import * as React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import ProjectsOverview from "../components/ProjectsOverview"
import CalltoAction from "../components/CalltoAction"

const WorkPage = () => (
  <Layout>
    <SEO title="Contact" />
    <ProjectsOverview />
    <CalltoAction />
  </Layout>
)

export default WorkPage
